<template>
  <main>
    <DashboardNavbar/>
    <router-view/>
    <DashboardFooter />
  </main>
</template>

<script>
import DashboardNavbar from './DashboardNavbar.vue';
import DashboardFooter from './DashboardFooter.vue';

export default {
  name: 'DashboardLayout',
  components: {
      DashboardNavbar,
      DashboardFooter
  }
}
</script>
