
import axios from 'axios';
import { processPromise } from '@helpers/functions';

const viacep = {};

viacep.api = (cep) => `https://viacep.com.br/ws/${cep}/json`;

viacep.search = (cep) => {
  return new Promise((resolve, reject) => {
    let endpoint = viacep.api(cep);
    axios.get(endpoint).then((result) => {
      console.log('result', result)
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
});

}

export default viacep
