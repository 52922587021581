<template>
  <div :class="!classPrefix ? 'form__group' : `${classPrefix}__group`" :style="`--width: ${width || '100%'}`">
    <label :class="!classPrefix ? 'form__label' : `${classPrefix}__label`">
      {{ label }}:
      <font-awesome-icon :icon="['fas', 'circle-exclamation']" v-if="required" />
    </label>

    <select :class="!classPrefix ? 'form__select' : `${classPrefix}__select`" v-model="selectedValue" @input="updateItem" :id="idx" >
      <option v-for="(item, index) in dataList" :key="index" :value="item.value">
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    label: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: [Number, String, Boolean, Object],
      required: false
    },
    dataList: {
      type: Array,
      required: true
    },
    idx: {
      type: String,
      required: false
    },
    classPrefix: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    selectedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    updateItem(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>
