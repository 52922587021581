import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'
import {
  faHouse,
  faDollarSign,
  faCalendarWeek,
  faUserGroup,
  faUserShield,
  faPowerOff,
  faUsers,
  faChartSimple,
  faCashRegister,
  faPenFancy,
  faAnglesLeft,
  faAnglesRight,
  faAngleLeft,
  faAngleRight,
  faHashtag,
  faTriangleExclamation,
  faCircleExclamation,
  faSpinner,
  faEye,
  faEyeSlash,
  faBarcode,
  faMoneyBillWave,
  faFileInvoice,
  faUpRightFromSquare,
  faFaceLaughWink,
  faFaceGrinWink,
  faSmileWink,
  faCircleCheck,
  faCalendarDay,
  faMagnifyingGlass,
  faBuildingColumns,
  faSackDollar,
  faShuffle
} from '@fortawesome/free-solid-svg-icons'


//look out:
// https://fontawesome.com/search?o=r&m=free

//icon to use
library.add(
  faHouse,
  faDollarSign,
  faCalendarWeek,
  faUserGroup,
  faPowerOff,
  faUserShield,
  faUsers,
  faChartSimple,
  faCashRegister,
  faPenFancy,
  faAnglesLeft,
  faAnglesRight,
  faAngleLeft,
  faAngleRight,
  faHashtag,
  faTriangleExclamation,
  faCircleExclamation,
  faSpinner,
  faEye,
  faEyeSlash,
  faBarcode,
  faMoneyBillWave,
  faFileInvoice,
  faUpRightFromSquare,
  faFaceLaughWink,
  faFaceGrinWink,
  faSmileWink,
  faCircleCheck,
  faCalendarDay,
  faMagnifyingGlass,
  faBuildingColumns,
  faSackDollar,
  faShuffle
);

export { FontAwesomeIcon };
