<template>
  <div class="navbar">
    <div class="container">
      <div class="navbar__wrap">
        <div class="navbar__logo">
          <LogoSvg maxWidth="30px" color1="#00aeef" color2="#160e44"/>
          <span>Flize Core</span>
        </div>

        <div class="navbar__menu">
          <div class="navbar__left">
            <nav class="navbar__menu navbar__menu--main">
                <span class="navbar__space">|</span>

                <router-link v-for="(item, index) in menuPrimary" :key="index" class="navbar__anchor" :class="activeClass(item.path)" :to="item.path" :title="item.name" v-show="roleRule(item.roles)">
                  <font-awesome-icon :icon="['fas', item.icon]" />
                  <span>{{ item.name }}</span>
                </router-link>
            </nav>
          </div>

          <div class="navbar__right">
            <nav class="navbar__menu navbar__menu--secondary">
              <router-link v-for="(item, index) in menuSecondary" :key="index" class="navbar__anchor" :class="activeClass(item.path)" :to="item.path" :title="item.name" v-show="roleRule(item.roles)">
                <font-awesome-icon :icon="['fas', item.icon]" />
              </router-link>

              <span class="navbar__space">|</span>

              <router-link v-for="(item, index) in menuTerciary" :key="index" class="navbar__anchor" :class="activeClass(item.path)" :to="item.path" :title="item.name">
                <font-awesome-icon :icon="['fas', item.icon]" />
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoSvg from './LogoSvg.vue';
import storage from '@/config/storage';

export default {
  name: 'DashboardNavbar',
  components: {
    LogoSvg
  },
  data() {
    return {
      menuPrimary: [
        { name: 'Dashboard', path: '/dashboard', icon: 'home', roles: ['admin', 'gerente'] },
        { name: 'Faturas', path: '/faturas', icon: 'dollar-sign', roles: ['admin', 'gerente'] },
        { name: 'Agendamentos', path: '/agendamentos', icon: 'calendar-week', roles: ['admin', 'gerente'] },
        { name: 'Clientes', path: '/clientes', icon: 'user-group', roles: ['admin', 'gerente'] }
      ],
      menuSecondary: [
        { name: 'Bancos', path: '/bancos', icon: 'cash-register', roles: ['admin'] },
        { name: 'Cronlogs', path: '/cronlogs', icon: 'chart-simple', roles: ['admin', 'gerente'] },
        { name: 'Usuários', path: '/usuarios', icon: 'users', roles: ['admin'] }
      ],
      menuTerciary: [
        { name: 'Meu Perfil', path: '/usuarios/f/' + storage.get('flizecore--user', '_id'), icon: 'user-shield' },
        { name: 'Sair', path: '/sair', icon: 'power-off' }
      ],
    }
  },
  methods: {
    activeClass(routeName) {
      routeName = routeName.replace('/', '');
      const routes = this.$route.path.split('/');
      const hasPath = routes.filter(item => item == routeName);
      let cssClass = '';
      if(hasPath && hasPath.length > 0) cssClass = 'navbar__anchor--active';
      return cssClass;
    },
    roleRule(roles) {
      const userRole = storage.get('flizecore--user', 'role');
      const filtered = roles.filter(item => item == userRole)
      return filtered.length > 0 ? true : false;
    }
  }
}
</script>
