<template>
  <section>
    <HeroSection headName="Usuários" goLabel="Cadastrar Usuários" goRoute="/usuarios/f" :searchBar="true" :addButton="true">
      <div class="searchbar__wrap">
        <div class="searchbar__group">
          <label for="" class="searchbar__label">
            Pesquisar:
          </label>

          <input class="searchbar__input searchbar__input--cliente" type="text" v-model="filters.search" @keyup.enter="doSearch()" >
        </div>
      </div>
    </HeroSection>

    <div class="container">

      <div class="table">
        <DataLoading rows="5" height="100px" v-if="loading"/>

        <NoData v-if="!loading && dataList.length == 0"/>

        <div class="table__responsive" v-if="!loading && dataList.length">
          <table>
            <thead>
              <tr>
                <th class="a-center">#</th>
                <th class="a-left">Nome Completo</th>
                <th class="a-left">E-mail de Acesso</th>
                <th class="a-left">Permissão</th>
                <th class="a-center">Último Acesso</th>
                <th class="a-center">Cadastro desde</th>
                <th class="a-center">Ações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in dataList" :key="index">
                <td class="a-center">{{ padVal(index + 1, 4) }}</td>
                <td class="table__box">
                  <div class="table__lbox">
                    <img :src="baseUrl('/img/user.jpg')" :alt="item.fullname">
                  </div>

                  <div class="table__rbox">
                    <span>
                      {{ item.fullname }}
                    </span>
                  </div>
                </td>
                <td class="a-left">
                  <span>
                    {{ item.email }}
                  </span>
                </td>
                <td class="a-left">
                  <BadgetInfo :label="userRoles(item.role, 'label')" :color="userRoles(item.role, 'color')" />
                </td>
                <td class="a-center">{{ brDateWithTime(item.last_access) }}</td>
                <td class="a-center">{{ brDate(item.created_at) }}</td>
                <td class="a-center">
                  <router-link class="table__go" :to="`/usuarios/f/${item._id}`" title="Editar">
                    <font-awesome-icon :icon="['fas', 'pen-fancy']" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PaginationGuide prefix="clientes" :data="pagination" :filters="filters"/>
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import NoData from '@components/_partials/NoData.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import BadgetInfo from '@components/_partials/BadgetInfo.vue';
import PaginationGuide from '@components/_partials/PaginationGuide.vue';
import { baseUrl, padVal, brDate, docMask, phoneMask } from '@helpers/functions';
import { userRoles } from '@helpers/constants';
import usuario from '@services/usuario';
import moment from 'moment';

export default {
  components: {
    HeroSection,
    PaginationGuide,
    NoData,
    DataLoading,
    BadgetInfo
  },
  methods: {
    baseUrl,
    brDate,
    padVal,
    docMask,
    phoneMask,
    userRoles,
    initLoad() {
      const _this = this;
      const { search } = _this.filters;
      const params = {
        page: _this.$route.query.page ? _this.$route.query.page : null,
        search
      };

      _this.loading = true;
      usuario.retrieve(params).then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          const { docs, ...pagination } = res.data;
          _this.dataList = docs;
          _this.pagination = pagination;
        }
      });
    },
    doSearch() {
      const _this = this;
      const search = _this.filters.search;
      if(search.length > 3) {
        _this.initLoad();
        _this.filters.search = '';
      }
      if(!search.length) {
        _this.initLoad();
      }
    },
    brDateWithTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      pagination: {},
      filters: {}
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    }
  },
  mounted(){
    this.initLoad(false);
  }
};

</script>
