<template>
  <div class="form__group" :style="`--width: ${width || '100%'}`">
    <label class="form__label">
      {{ label }}:
      <font-awesome-icon :icon="['fas', 'circle-exclamation']" v-if="required" />
    </label>

    <QuillEditor theme="snow" contentType="html" :toolbar="toolbar" v-model:content="editorContent" @update:content="onEditorChange"/>
  </div>
</template>

<script>

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  components: {
    QuillEditor,
  },
  data() {
    return {
      toolbar: ['bold', 'italic', 'underline', { 'list': 'ordered' }, { 'list': 'bullet' }],
      editorContent: this.modelValue || ''
    }
  },
  name: 'FormEditor',
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: true
    },
    width: {
      type: String,
      required: false
    },
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onEditorChange(content) {
      this.editorContent = content;
      this.$emit('update:modelValue', content);
    }
  }
}
</script>

<style>
.ql-editor, .ql-blank {
  min-height: 300px;
  font-size: 18px;
}
</style>
