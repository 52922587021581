
import api from 'api';
import { processPromise } from '@helpers/functions';

const login = {};

login.doLogin = (email, password) => {
  return new Promise((resolve, reject) => {
    api.post('/api/login', {email , password}).then((result) => {
      const response = processPromise(true, result);
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
});

}


export default login
