<template>
  <div :class="!classPrefix ? 'form__group' : `${classPrefix}__group`" :style="`--width: ${width || '100%'}`">
    <label :class="!classPrefix ? 'form__label' : `${classPrefix}__label`">
      {{ label }}:
      <font-awesome-icon :icon="['fas', 'circle-exclamation']" v-if="required" />
    </label>

    <input :type="!typex ? 'text' : typex" :class="!classPrefix ? 'form__input' : `${classPrefix}__select`" :value="modelValue" @input="updateValue" v-if="!money && !mask" :id="idx" />

    <input :type="!typex ? 'text' : typex" :class="!classPrefix ? 'form__input' : `${classPrefix}__select`" :value="modelValue" @input="updateValue" v-if="!money && mask" v-mask="`${mask}`" :id="idx" />

    <input :type="!typex ? 'text' : typex" :class="!classPrefix ? 'form__input' : `${classPrefix}__select`" :value="modelValue" @input="updateValue" v-if="money === true" v-mask-decimal.br="2" :id="idx" />
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    label: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: [Number, String],
      required: false
    },
    mask: {
      type: String,
      required: false
    },
    money: {
      type: Boolean,
      required: false
    },
    idx: {
      type: String,
      required: false
    },
    typex: {
      type: String
    },
    classPrefix: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>
