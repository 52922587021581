<template>
  <div class="form__image">
    <div class="form__preview">
      <img :src="modelImage ? modelImage : baseUrl('/img/user.jpg')" :alt="label" id="preview">
    </div>

    <div class="form__actions">
      <button type="button" class="form__upload" @click="hiddenClick()">
        Anexar Imagem
      </button>

      <div class="form__isizes">
        300x300px - JPG/PNG - MAX: 200KB
      </div>

      <input type="file" class="hidden" id="upload" @change="hiddenChange">
    </div>
  </div>
</template>

<script>
import { baseUrl } from '@helpers/functions';

export default {
  name: 'FormImage',
  props: {
    label: {
      type: String,
      required: true
    },
    modelImage: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelImage'],
  methods: {
    baseUrl,
    hiddenClick() {
      const el = document.getElementById('upload');
      el.click();
    },
    hiddenChange(event) {
      console.log('event', event);
      //const _this = this;
      if(event.target.files.length > 0){
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
          this.$emit('update:modelImage', reader.result);
        };
      }
    }
  }
}
</script>
