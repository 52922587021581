<template>
  <div class="form__bottom">
    <button type="button" class="form__submit form__submit--spinner" :class="`form__submit--${data.color}`" @click="submit()">
      <font-awesome-icon :icon="['fa', 'spinner']" v-show="data.spinner"/>
      {{ data.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'FormBottom',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    submit() {
      this.$emit('submit');
    }
  }
}
</script>
