<template>
  <section>
    <HeroSection headName="Agendamentos" goLabel="Cadastrar Agendamento" goRoute="/agendamentos/f" :searchBar="true" :addButton="true">
      <div class="searchbar__wrap">
        <FormSelect label="Serviço" :required="false" :dataList="typeServices()" v-model:modelValue="filters.servico" classPrefix="searchbar" />

        <FormSelect label="Período" :required="false" :dataList="schedulePeriod()" v-model:modelValue="filters.periodo" classPrefix="searchbar" />

        <FormSelect label="Status" :required="false" :dataList="isActive()" v-model:modelValue="filters.status" classPrefix="searchbar" />

        <div class="searchbar__group">
          <label for="" class="searchbar__label">
            Pesquisar:
          </label>

          <input class="searchbar__input searchbar__input--cliente" type="text" v-model="filters.search" @keyup.enter="doSearch()" >
        </div>
      </div>
    </HeroSection>

    <div class="container">

      <div class="table">
        <DataLoading rows="5" height="100px" v-if="loading"/>

        <NoData v-if="!loading && dataList.length == 0"/>

        <div class="table__responsive" v-if="!loading && dataList.length">
          <table>
            <thead>
              <tr>
                <th class="a-center">#</th>
                <th class="a-left">Cliente</th>
                <th class="a-center">Serviço</th>
                <th class="a-right">Valor</th>
                <th class="a-center">Próximo Envio</th>
                <th class="a-center">Dias para Envio</th>
                <th class="a-center">Próximo Vencimento</th>
                <th class="a-center">Periodicidade</th>
                <th class="a-center">Status</th>
                <th class="a-center">-</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in dataList" :key="index">
                <td class="a-center">{{ padVal(item._cod, 4) }}</td>
                <td class="table__box">
                  <div class="table__lbox">
                    <img v-if="!item.image" :src="baseUrl('/img/user.jpg')" :alt="item.company">
                    <img v-if="item.image" :src="item.image" :alt="item.company">
                  </div>

                  <div class="table__rbox">
                    <span>
                      {{ cutString(item.customer.company, 4) }}
                    </span>

                    <span>
                      {{ cutString(item.customer.name, 5) }}
                    </span>

                    <span>
                      {{ docMask(item.customer.document, true) }}
                    </span>
                  </div>
                </td>
                <td class="a-center">
                  <BadgetInfo :label="typeServices(item.service_type, 'label')" :color="typeServices(item.service_type, 'color')" />
                </td>
                <td class="a-right">
                  {{ brMoney(item.schedule_value) }}
                </td>
                <td class="a-center">
                  {{ brDate(item.next_send_date) }}
                </td>
                <td class="a-center">
                  {{ item.days_to_send }}
                </td>
                <td class="a-center">
                  {{ brDate(item.next_maturity) }}
                </td>
                <td class="a-center">
                  <BadgetInfo :label="schedulePeriod(item.periodicidy, 'label')" :color="schedulePeriod(item.periodicidy, 'color')" />
                </td>
                <td class="a-center">
                  <BadgetInfo :label="isActive(item.is_active, 'label')" :color="isActive(item.is_active, 'color')" />
                </td>
                <td class="a-center">
                  <router-link class="table__go" :to="`/agendamentos/f/${item._id}`" title="Editar">
                    <font-awesome-icon :icon="['fas', 'pen-fancy']" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PaginationGuide prefix="agendamentos" :data="pagination" :filters="filters" />
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import NoData from '@components/_partials/NoData.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import FormSelect from '@components/_form/FormSelect.vue';
import BadgetInfo from '@components/_partials/BadgetInfo.vue';
import PaginationGuide from '@components/_partials/PaginationGuide.vue';
import { baseUrl, cutString, brMoney, padVal, brDate, docMask, phoneMask } from '@helpers/functions';
import { typeServices, schedulePeriod, isActive } from '@helpers/constants'
import agendamento from '@services/agendamento';

export default {
  components: {
    HeroSection,
    PaginationGuide,
    NoData,
    BadgetInfo,
    FormSelect,
    DataLoading
  },
  methods: {
    baseUrl,
    cutString,
    brDate,
    padVal,
    docMask,
    phoneMask,
    brMoney,
    typeServices,
    schedulePeriod,
    isActive,
    initLoad() {
      const _this = this;
      const { servico, periodo, status, proximo_vencimento, search } = _this.filters;
      const params = {
        page: _this.$route.query.page ? _this.$route.query.page : null,
        servico,
        periodo,
        status,
        proximo_vencimento,
        search
      };

      _this.loading = true;
      agendamento.retrieve(params).then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          const { docs, ...pagination } = res.data;
          _this.dataList = docs;
          _this.pagination = pagination;
        }
      });
    },
    doSearch() {
      const _this = this;
      const search = _this.filters.search;
      if(search.length > 3) {
        _this.initLoad();
        _this.filters.search = '';
      }
      if(!search.length) {
        _this.initLoad();
      }
    },
    mountQueryString() {
      const _this = this;
      const { servico, periodo, proximo_vencimento, status } = _this.$route.query;

      _this.filters = {
        servico,
        periodo,
        proximo_vencimento,
        status
      }
    },
    goToWithQueryString(key, filter) {
      let path = 'agendamentos';
      let { ...queries } = this.$route.query;

      queries[key] = filter;
      this.$router.push({
        path,
        query: queries
      });
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      pagination: {},
      filters: {}
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    },
    'filters.servico'(servico) {
      this.goToWithQueryString('servico', servico);
    },
    'filters.periodo'(periodo) {
      this.goToWithQueryString('periodo', periodo);
    }
    ,
    'filters.status'(status) {
      this.goToWithQueryString('status', status);
    }
  },
  mounted(){
    this.mountQueryString();
    this.initLoad(false);
  }
};

</script>
