<template>
  <div class="fatura">
    <div class="fcontainer">
      <DataLoading rows="8" height="100px" v-if="loading"/>

      <div class="fshow" v-if="!loading">
        <div class="flogo">
          <img src="https://assets.flize.cloud/arquivos/flize_billing.png" alt="Flize Tecnologia" width="120" height="60" />
        </div>

        <header class="fheader">
          <div class="finfo">
            <h1 class="fname">Fatura Flize</h1>
            <h2 class="fkey">Identificador: #{{ data._cod }}</h2>
          </div>

          <div>
              <span class="fstatus" :class="invoiceStatus(data.invoice_status, 'class')">
                {{ invoiceStatus(data.invoice_status, 'label') }}
              </span>
          </div>
        </header>

        <section>
          <ul class="fcustomer">
            <li>
                <span>Cliente:</span>

                <span>{{ data?.customer?.name }}</span>
            </li>

            <li>
                <span>CNPJ/CPF:</span>

                <span>{{ docMask(data?.customer?.document) }}</span>
            </li>
          </ul>

          <ul class="finfos">
            <li>
                <span>Vencimento:</span>

                <span>{{ brDate(data.maturity_date) }}</span>
            </li>

            <li>
                <span>Valor:</span>

                <span>{{ brMoney(data.invoice_value) }}</span>
            </li>

            <li>
                <span>Periodicidade:</span>


                <span>{{ data.schedule_id ? schedulePeriod(data.periodicidy, 'label') : 'Não se aplica' }}</span>
            </li>

            <li>
              <span>Tipo:</span>

              <span>{{ typeServices(data.service_type, 'label') }}</span>
            </li>
          </ul>

          <ul class="fdescription">
            <li>
                <span>Descritivo da Fatura:</span>

                <span v-html="data.full_desc "></span>
            </li>
          </ul>

          <div class="freseller" v-if="data.service_type == 'revenda_cloud'">
            <font-awesome-icon :icon="['fa', 'users']" />

            <span><strong>Caro Revendedor:</strong> Verifique suas contas e cotas acessando a opção abaixo em "Planilha de Contas"</span>
          </div>


          <div class="fterms">
            <label class="fcheckwrap">Li e concordo com os termos da Flize Tecnologia conforme descrito <a :href="data.terms" title="Termos de Uso" target="_blank">neste documento</a>.
                <input type="checkbox" :checked="acepted" @change="onAcept"/>
                <span class="fcheckmark" ></span>
            </label>
          </div>

          <ul class="fbuttons" v-if="payment_url">
            <li class="go--js" :class="enableButtons === true ? '' : 'fdisabled'">
              <a :href="payment_url" title="Pagar com Boleto" target="_blank">
                  <font-awesome-icon :icon="['fa', 'money-bill-wave']" />

                  <h3>Efetuar Pagamento</h3>
              </a>
            </li>

            <li class="go--js"  :class="enableButtons === true ? '' : 'fdisabled'" v-if="data.has_nfse && data.nfse_data">
              <a :href="nfseUrl(data.nfse_data)" title="Ver Nota Fiscal" target="_blank">
                  <font-awesome-icon :icon="['fa', 'file-invoice']" />

                  <h3>Ver Nota Fiscal</h3>
              </a>
            </li>

            <li class="go--js"  :class="enableButtons === true ? '' : 'fdisabled'" v-if="data.service_type == 'revenda_cloud'">
              <a :href="`${baseUrl('', 'api')}/api/cliente/planilha/${data.token}`" title="Fazer Download" target="_blank">
                <font-awesome-icon :icon="['fa', 'users']" />

                <h3>Planilha de Contas</h3>
              </a>
            </li>
          </ul>
        </section>

        <footer class="ffooter">
          <h4>
            <font-awesome-icon :icon="['fa', 'fa-triangle-exclamation']" />
              FIQUE ATENTO!
          </h4>

          <ul>
              <li>Apenas é enviado e-mails de cobrança sendo o remetente <a href="mailto:noreply@flize.com.br" title="E-mail Flize" target="_blank">noreply@flize.com.br</a> ou <a href="mailto:financeiro@flize.com.br" title="E-mail Flize" target="_blank">financeiro@flize.com.br</a>. Caso receba alguma cobrança de outros e-mails desconsiderar totalmente.</li>
              <li>Para mais informações jurídicas acesse nossos termos <a :href="data.terms" title="Termos de Uso" target="_blank">clicando aqui</a>.</li>
          </ul>
        </footer>

        <div class="fdev">
          <a href="https://flize.com.br" title="Flize Tecnologia" target="_blank">
              Flize Tecnologia | 2011 - {{ year }}
          </a>
        </div>

        <div class="fshare">
          <a :href="`https://api.whatsapp.com/send?text=${baseUrl()}/cliente/fatura/${data.token}`" title="Enviar via Whatsapp" target="_blank">
            <font-awesome-icon :icon="['fa', 'up-right-from-square']" />
        </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DataLoading from '@components/_partials/DataLoading.vue';
import { baseUrl, brMoney, brDate, docMask, nfseUrl } from '@helpers/functions';
import { invoiceStatus, schedulePeriod, typeServices } from '@helpers/constants'
import fatura from '@services/fatura';

export default {
  components: {
    DataLoading
  },
  data() {
    return {
      loading: false,
      data: {},
      acepted: false,
      enableButtons: false,
      payment_url: '',
      year: new Date().getFullYear()
    }
  },
  methods: {
    invoiceStatus,
    schedulePeriod,
    typeServices,
    baseUrl,
    brMoney,
    brDate,
    docMask,
    nfseUrl,
    initLoad() {
      const _this = this;
      const _id =  _this.$route.params.id ? _this.$route.params.id : null;
      const no =  _this.$route.params.noview ? _this.$route.params.noview : null;

      _this.loading = true;
      fatura.public(_id, no).then((res) => {
        _this.loading = false;
        if(typeof res !== 'undefined' && res.success) {
          const { data } = res;
          console.log('data', data)
          if(data.has_gn_legacy === true) {
            _this.payment_url = data.gn_legacy.link;
          }
          if(data.has_gn_legacy === false && data.iugu_data) {
            _this.payment_url = data.iugu_data.checkout_url;
          }
          _this.data = data;
          document.title = `Fatura #${data._cod} | Flize Tecnologia`;
        }
      });
    },
    onAcept(event) {
      this.enableButtons = event.target.checked;
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    }
  },
  mounted(){
    this.initLoad();
  }
};

</script>
