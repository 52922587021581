<template>
  <section>
    <HeroSection headName="Dashboard" :searchBar="false" :addButton="false">
    </HeroSection>

    <div class="container">
      <DataLoading rows="1" height="100px" v-if="loading"/>

      <DataLoading rows="1" height="300px" v-if="loading"/>

      <div class="dcontent">
        <ul class="dlist">
          <li class="ditem" v-for="(item, index) in dboxes" :key="index">
            <div class="dbox" :class="item.class">
              <div class="dhead">
                <font-awesome-icon :icon="['fa', item.icon]" />

                {{ item.title }}
              </div>

              <div class="dbody">
                <span class="dnum">
                  {{ item.value }}
                </span>

                <span class="dpos">
                  <router-link :to="{ path: 'faturas', query: item.params }">
                  <font-awesome-icon :icon="['fa', 'eye']"/>
                  </router-link>
                </span>
              </div>
            </div>
          </li>
        </ul>

        <ul class="mlist">
          <li class="mitem" v-for="(item, index) in mboxes" :key="index">
            <div class="mbox" :class="`mbox--${item.class}`">
              <div class="mhead">
                <font-awesome-icon :icon="['fa', item.icon]" />

                {{ item.title }}
              </div>

              <div class="mbody">
                <div class="mrow">
                  <span>Previsão Mensal:</span>
                  <span v-if="item.id == 1">{{ brMoney(item.data.allSchedulesMonth) }}</span>
                  <span v-if="item.id != 1">{{ brMoney(item.data.totalMonth) }}</span>
                  <span class="mbar" :class="`mbar--${item.class}`" style="--width: 100%">
                    <i>100%</i>
                  </span>
                </div>

                <div class="mrow">
                  <span>Agendamentos:</span>
                  <span>{{ brMoney(item.data.schedulesMonth) }}</span>
                  <span v-if="item.id == 1" class="mbar" :class="`mbar--${item.class}`" :style="`--width: ${item.data.allSchedulePorcent}%`">
                    <i>{{ item.data.allSchedulePorcent }} %</i>
                  </span>
                  <span v-if="item.id != 1" class="mbar" :class="`mbar--${item.class}`" :style="`--width: ${item.data.schedulePorcent}%`">
                    <i>{{ item.data.schedulePorcent }} %</i>
                  </span>
                </div>

                <div class="mrow">
                  <span>Faturas Geradas:</span>
                  <span>{{ brMoney(item.data.invoicesMonth) }}</span>
                  <span class="mbar" :class="`mbar--${item.class}`" :style="`--width: ${item.data.invoicePorcent}%`">
                    <i>{{ item.data.invoicePorcent }} %</i>
                  </span>
                </div>

                <div class="mrow">
                  <span>Faturas Pagas:</span>
                  <span>{{ brMoney(item.data.payedMonth) }}</span>
                  <span class="mbar" :class="`mbar--${item.class}`" :style="`--width: ${item.data.payedProcent}%`">
                    <i>{{ item.data.payedProcent }} %</i>
                  </span>
                </div>
              </div>

              <table class="mtable" v-if="item.data.invoicesMap.length > 0 || item.data.scheduleMap.length > 0">
                <thead>
                  <tr>
                    <th class="a-center">Ano</th>
                    <th class="a-center">Mês</th>
                    <th class="a-center">Dia</th>
                    <th class="a-center">Faturas</th>
                    <th class="a-right">Valor Pendente</th>
                    <th class="a-center">Previsão</th>
                    <th class="a-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(invoice, index2) in item.data.invoicesMap" :key="index2">
                    <td class="a-center">{{ invoice._id.year }}</td>
                    <td class="a-center">{{ monthLabel(invoice._id.month) }}</td>
                    <td class="a-center">{{ invoice._id.day }}</td>
                    <td class="a-center">{{ invoice.count }}</td>
                    <td class="a-right">{{ brMoney(invoice.totalValue) }}</td>
                    <td class="a-center">
                      <span class="mbadge mbadge--fatura">Fatura</span>
                    </td>
                    <td class="a-center">
                      <router-link :to="{ path: 'faturas', query: { status: 'pendente', data_vencimento: getDateFromId(invoice._id) } }">
                        <font-awesome-icon :icon="['fa', 'magnifying-glass']"/>
                      </router-link>
                    </td>
                  </tr>
                  <tr v-for="(schedule, index3) in item.data.scheduleMap" :key="index3">
                    <td class="a-center">{{ schedule._id.year }}</td>
                    <td class="a-center">{{ monthLabel(schedule._id.month) }}</td>
                    <td class="a-center">{{ schedule._id.day }}</td>
                    <td class="a-center">{{ schedule.count }}</td>
                    <td class="a-right">{{ brMoney(schedule.totalValue) }}</td>
                    <td class="a-center">
                      <span class="mbadge mbadge--agendamento">Agendamento</span>
                    </td>
                    <td class="a-center">
                      <router-link :to="{ path: 'agendamentos', query: { status: true, proximo_vencimento: getDateFromId(schedule._id) } }">
                        <font-awesome-icon :icon="['fa', 'magnifying-glass']"/>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>

import DataLoading from '@components/_partials/DataLoading.vue';
import HeroSection from '@components/_partials/HeroSection.vue';
import { brMoney, getDateFromId } from '@helpers/functions';
import { monthLabel } from '@helpers/constants';
import moment from 'moment';
import dashboard from '@services/dashboard';

export default {
  components: {
    DataLoading,
    HeroSection
  },
  data() {
    return {
      loading: false,
      dboxes: [],
      mboxes: []
    }
  },
  methods: {
    brMoney,
    getDateFromId,
    monthLabel,
    initLoad() {
      const _this = this;
      _this.loading = true;
      dashboard.details().then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          _this.mountInfoBoxes(res.data);

          const months = [
            { id: -1, class: 'before', title: 'Mês Anterior', icon: 'calendar-week' },
            { id: 0, class: 'now', title: 'Mês Atual', icon: 'calendar-day' },
            { id: 1, class: 'after', title: 'Próximo Mês', icon: 'calendar-week' },
          ];

          months.forEach((item, key) => _this.mountMapTables(months[key], res.data[`month-${item.id}`]));


        }
      });
    },
    mountInfoBoxes(dataList) {
      const _this = this;
      const { invoicesToday, invoicesWeek, invoicesMonth, invoicesByStatus } = dataList;

      let dboxes = [];

      dboxes.push({ icon: 'face-laugh-wink', class: 'dbox--hoje',title: 'Hoje', value: invoicesToday.length, params: { data_pagamento: moment().format('YYYY-MM-DD') } });
      dboxes.push({ icon: 'face-grin-wink', class: 'dbox--semana', title: 'Esta Semana', value: invoicesWeek.length, params: { semana: moment().format('YYYY-MM-DD') } });
      dboxes.push({ icon: 'face-smile-wink', class: 'dbox--mes', title: 'Este Mês', value: invoicesMonth.length, params: { mes: moment().format('YYYY-MM-DD') } });

      dboxes.push({ icon: 'circle-check', class: 'dbox--emitida', title: 'Emitidas', value: invoicesByStatus.emitida.length, params: { status: 'emitida' } });
      dboxes.push({ icon: 'circle-exclamation', class: 'dbox--vencendo', title: 'Vencendo', value: invoicesByStatus.vencendo.length, params: { status: 'vencendo' } });
      dboxes.push({ icon: 'triangle-exclamation', class: 'dbox--vencida', title: 'Vencidas', value: invoicesByStatus.vencida.length, params: { status: 'vencida' } });

      _this.dboxes = dboxes;
    },
    mountMapTables(month, monthData) {

      const _this = this;

      const { invoicesMap, scheduleMap, invoicesPayed, invoicesMonthly, schedulesMonthly, allSchedulesMonthly } = monthData;

      const invoicesMonth = invoicesMonthly.length && invoicesMonthly[0]?.totalValue ? invoicesMonthly[0].totalValue : 0;
      const schedulesMonth = schedulesMonthly.length && schedulesMonthly[0]?.totalValue ? schedulesMonthly[0].totalValue : 0;
      const totalMonth = invoicesMonth + schedulesMonth;
      const payedMonth = invoicesPayed.length && invoicesPayed[0].totalValue ? invoicesPayed[0].totalValue : 0;

      const allSchedulesMonthMensal = allSchedulesMonthly.length && allSchedulesMonthly[0]?.totalValue ? allSchedulesMonthly[0].totalValue : 0;
      const allSchedulesMonthParc = allSchedulesMonthly.length && allSchedulesMonthly[1]?.totalValue ? allSchedulesMonthly[1].totalValue : 0;
      const allSchedulesMonth = allSchedulesMonthMensal + allSchedulesMonthParc;

      const invoicePorcent = Math.round((invoicesMonth / totalMonth) * 100);
      const schedulePorcent = Math.round((schedulesMonth / totalMonth) * 100);
      const payedProcent = Math.round((payedMonth / totalMonth) * 100);
      const allSchedulePorcent = Math.round((schedulesMonth / allSchedulesMonth) * 100);

      month.data = { allSchedulesMonth, totalMonth, schedulesMonth, invoicesMonth, payedMonth, allSchedulePorcent, schedulePorcent, invoicePorcent, payedProcent, invoicesMap, scheduleMap };
      _this.mboxes.push(month);

    }
  },
  mounted(){
    this.initLoad();
  }
};

</script>
