<template>
  <svg :style="`width: 100%; max-width: ${maxWidth}`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.9 80">
    <defs>
    </defs>

    <g id="Camada_1" data-name="Camada 1" focusable="false">
        <polygon :style="`fill: ${color1}`" points="43.29 40.64 22.95 53.07 2.6 40.64 22.95 5.73 43.29 40.64"/>
        <polygon :style="`fill: ${color2}`" points="22.95 57.06 2.6 44.63 22.95 74.27 43.3 44.63 22.95 57.06 22.95 57.06"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoSvg',
  props: {
    maxWidth: {
      type: String,
      required: true
    },
    color1: {
      type: String,
      required: true
    },
    color2: {
      type: String,
      required: true
    }
  }
}
</script>
