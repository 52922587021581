<template>
  <div class="pagination" v-if="data && data.totalPages > 1">
    <ul class="pagination__list">
      <li class="pagination__item" v-if="data.hasPrevPage">
        <router-link class="pagination__anchor" :to="`/${prefix}?page=${1}${filter}`" :title="`Primeira Página`">
          <font-awesome-icon :icon="['fas', 'angles-left']" />
        </router-link>
      </li>

      <li class="pagination__item" v-if="data.prevPage">
        <router-link class="pagination__anchor" :to="`/${prefix}?page=${data.prevPage}${filter}`" :title="`Página Anterior`">
          <font-awesome-icon :icon="['fas', 'angle-left']" />
        </router-link>
      </li>

      <li class="pagination__item" v-for="(item, index) in pages" :key="index">
        <router-link class="pagination__anchor" :to="`/${prefix}?page=${item}${filter}`" :title="`Página ${item}`" :class="{'pagination__anchor--active': data.page == item}">
          {{ item }}
        </router-link>
      </li>

      <li class="pagination__item" v-if="data.nextPage">
        <router-link class="pagination__anchor" :to="`/${prefix}?page=${data.nextPage}${filter}`" :title="`Próxima Página`">
          <font-awesome-icon :icon="['fas', 'angle-right']" />
        </router-link>
      </li>

      <li class="pagination__item" v-if="data.hasNextPage">
        <router-link class="pagination__anchor" :to="`/${prefix}?page=${data.totalPages}${filter}`" :title="`Última Página`">
          <font-awesome-icon :icon="['fas', 'angles-right']" />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>

import { obj2Prams } from '@helpers/functions';

export default {
  name: 'PaginationGuide',
  props: {
    prefix: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: false
    }
  },
  methods: {
    generateSlotPages(currentPage, totalPages, maxVisiblePages = 10) {
      const half = Math.floor(maxVisiblePages / 2);
      let start = Math.max(1, currentPage - half);
      let end = Math.min(totalPages, currentPage + half);

      if (currentPage <= half) {
        end = Math.min(totalPages, maxVisiblePages);
      } else if (currentPage + half >= totalPages) {
        start = Math.max(1, totalPages - maxVisiblePages + 1);
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    initPagination() {
      if(!this.data) return;

      if(this.filters && obj2Prams(this.filters)) {
        this.filter = `&${obj2Prams(this.filters)}`;
      }

      const _this = this;
      const { page, totalPages } = _this.data;
      const pages = _this.generateSlotPages(page, totalPages);
      _this.pages = pages;
    }
  },
  data() {
    return {
      pages: [],
      filter: ''
    }
  },
  mounted() {
    this.initPagination();
  },
  watch: {
    data() {
      this.initPagination();
    }
  }
}
</script>
