<template>
  <div class="nodata">
    <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />

    <span>
      Nenhum resultado encontrado.
    </span>
  </div>
</template>

<script>
export default {
  name: 'NoData'
}
</script>
