
const axios = require('axios').default;
import storage from 'storage';
import config from 'config';

const local = 'flizecore--user';

export const api = axios.create({
  baseURL: config[process.env.NODE_ENV].api,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    'X-Access-Token': storage.get(local, 'access_token')
  }
});

api.interceptors.response.use(response => {
    //console.log('route interceptor =>', response.url)
    return response;
 }, error => {
   if (error?.response?.status === 401) {
    console.log('Bloqueio por API!')
    storage.clear(local);
    location.reload();
   }
   return error;
 });

export default api;
