<template>
  <section>
    <HeroSection headName="Cadastro de Cliente" :searchBar="false" :addButton="false">
    </HeroSection>

    <div class="container">

      <form class="form">
        <div class="form__box" v-if="loading">
          <h2 class="form__name">
            <DataLoading rows="1" height="50px" />
          </h2>

          <div class="form__content">
            <DataLoading rows="4" height="80px" />
          </div>
        </div>

        <div class="form__wrap" v-if="!loading">
          <div class="form__box">
            <h2 class="form__name">
              Dados Gerais
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="CPF" :required="true" v-model:modelValue="helpers.cpf" mask="000.000.000-00" v-if="isCpf" idx="cpf"/>
              </div>

              <div class="form__row">
                <FormInput label="CNPJ" :required="true" v-model:modelValue="helpers.cnpj" mask="00.000.000/0000-00" v-if="!isCpf"  idx="cnpj"/>
              </div>

              <div class="form__row">
                <FormInput label="Nome Fantasia" :required="true" v-model:modelValue="model.name"/>
              </div>

              <div class="form__row">
                <FormInput label="Razão Social" :required="true" v-model:modelValue="model.company"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Endereço
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="CEP" :required="true" v-model:modelValue="model.contact.zip_code" width="20%" mask="00000-000" />

                <FormInput label="Logradouro" :required="true" v-model:modelValue="model.contact.street_name" width="60%"/>

                <FormInput label="Número" :required="true" v-model:modelValue="model.contact.number" width="20%"/>
              </div>

              <div class="form__row">
                <FormInput label="Complemento" :required="false" v-model:modelValue="model.contact.complement" width="30%"/>

                <FormInput label="Bairro" :required="true" v-model:modelValue="model.contact.neighborhood" width="30%"/>

                <FormInput label="Cidade" :required="true" v-model:modelValue="model.contact.city" width="30%"/>

                <FormInput label="UF" :required="true" v-model:modelValue="model.contact.state" width="10%"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Dados Telefônicos
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="Celular 1" :required="true" v-model:modelValue="model.contact.celphone1" width="33%" mask="(00) 90000-0000"/>

                <FormInput label="Celular 2" :required="false" v-model:modelValue="model.contact.celphone2" width="33%" mask="(00) 90000-0000"/>

                <FormInput label="Celular 3" :required="false" v-model:modelValue="model.contact.celphone3" width="33%" mask="(00) 90000-0000"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              E-mails de Envio
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="E-mail 1" :required="true" v-model:modelValue="model.contact.email1" width="50%"/>

                <FormInput label="E-mail 2" :required="false" v-model:modelValue="model.contact.email2" width="50%"/>
              </div>

              <div class="form__row">
                <FormInput label="E-mail 3" :required="false" v-model:modelValue="model.contact.email3" width="50%"/>

                <FormInput label="E-mail 4" :required="false" v-model:modelValue="model.contact.email4" width="50%"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Serviços Vinculados
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="DirectAdmin" :required="false" v-model:modelValue="model.acc_services.directadmin" width="25%"/>

                <FormInput label="Proxmox Dexter" :required="false" v-model:modelValue="model.acc_services.proxmox_dexter" width="25%"/>

                <FormInput label="ModulaPro Emissor" :required="false" v-model:modelValue="model.acc_services.mp_emissor" width="25%"/>

                <FormInput label="Skymail" :required="false" v-model:modelValue="model.acc_services.skymail" width="25%"/>
              </div>

              <div class="form__text">
                Deixar vazio para não suspender serviços.
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Informações Extras
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="Palavras Chave" :required="true" v-model:modelValue="model.keywords" width="70%"/>

                <FormInput label="Cliente Desde" :required="true" v-model:modelValue="model.created_at" width="30%" typex="date" v-if="model.created_at"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Imagem
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormImage label="Logomarca do Cliente" v-model:modelImage="model.image" />
              </div>
            </div>
          </div>

          <FormBottom :data="button" @submit="submit()" />

        </div>
      </form>
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import FormInput from '@components/_form/FormInput.vue';
import FormImage from '@components/_form/FormImage.vue';
import FormBottom from '@components/_form/FormBottom.vue';
import { baseUrl, padVal, brDate, globalDate, docMask, phoneMask } from '@helpers/functions';
import cliente from '@services/cliente';
import viacep from '@services/viacep';

export default {
  components: {
    HeroSection,
    DataLoading,
    FormInput,
    FormImage,
    FormBottom
  },
  data() {
    return {
      loading: false,
      button: { spinner: false, label: 'Salvar Cliente', color: 'primary' },
      model: { contact: {}, acc_services: {} },
      dataList: [],
      pagination: {},
      search: '',
      isCpf: true,
      helpers: {
        cpf: '',
        cnpj: ''
      }
    }
  },
  methods: {
    baseUrl,
    brDate,
    padVal,
    docMask,
    phoneMask,
    loadForm: function(_id) {
      const _this = this;
      _this.loading = true;

      cliente.show(_id).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          _this.model = res.data;
          _this.loading = false;

          if(!_this.model.acc_services) {
            _this.model.acc_services = {}
          }

          _this.model.created_at = globalDate(_this.model.created_at);

          _this.helpers.cpf = _this.model.document;
          _this.isCpf = true;
          if(_this.model.document.length == 14) {
            _this.helpers.cnpj = _this.model.document;
            _this.isCpf = false;
          }
        }
      });
    },
    submit() {
      const _this = this;
      const _id = _this.hasIdx();
      const button = _this.button;

      let helpers = _this.helpers;
      let data = _this.model;

      data.document = helpers.cpf;
      if(helpers.cnpj.length == 18) data.document = helpers.cnpj;

      _this.button = {
        spinner: true,
        label: 'Salvando...',
        color: 'warning'
      }
      cliente.save(_id, data).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          _this.button = {
            spinner: false,
            label: 'Salvo com sucesso!',
            color: 'success'
          }

          setTimeout(() => this.$router.push('/clientes'), 1000);
        }

        if(typeof res === 'undefined' || !res.success) {
          _this.button = {
            spinner: false,
            label: 'Houve um erro ao processar.',
            color: 'danger'
          }

          setTimeout(() => _this.btnLabel = button, 3000);
        }
      })

    },
    hasIdx() {
      const _this = this;
      return _this.$route.params.id ? _this.$route.params.id : null;
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    },
    'helpers.cpf'(doc) {
      const _this = this;
      if(doc && doc.length >= 15) {
        _this.isCpf = false;
        _this.helpers.cnpj = doc;
        _this.model.document = doc;
        setTimeout(() => {
          document.getElementById('cnpj').focus();
        }, 200);
      }
    },
    'helpers.cnpj'(doc) {
      const _this = this;
      if(doc && doc.length <= 11) {
        _this.isCpf = true;
        _this.helpers.cpf = doc;
        _this.model.document = doc;
        setTimeout(() => {
          document.getElementById('cpf').focus();
        }, 200);
      }
    },
    'model.contact.zip_code'(cep) {
      const _this = this;
      if(cep && cep.length == 9) {
        viacep.search(cep).then((res) => {
          if(typeof res.erro !== 'undefined') {
            return;
          }

          _this.model.contact.street_name = res.logradouro;
          _this.model.contact.neighborhood = res.bairro;
          _this.model.contact.city = res.localidade;
          _this.model.contact.state = res.uf;
        })
      }
    }
  },
  mounted(){
    const _id = this.hasIdx();
    if(_id) this.loadForm(_id);
  }
};

</script>
