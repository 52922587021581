<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__wrap">
        <div class="footer__box a-left">
          <a target="_blank" href="https://core.flize.com.br" title="Flize Core">
            Flize Core {{ version }}
          </a>
        </div>

        <div class="footer__box a-right">
          <a target="_blank" href="https://flize.com.br" title="Flize Tecnologia">
            Flize Tecnologia - 2010 - {{ year }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import pkg from '../../../package.json'

export default {
  name: 'DashboardFooter',
  data() {
    return {
      version: `v${pkg.version}`,
      year: new Date().getFullYear()
    };
  }
}
</script>
