<template>
  <section>
    <HeroSection headName="Faturas" goLabel="Cadastrar Fatura" goRoute="/faturas/f" :searchBar="true" :addButton="true">
      <div class="searchbar__wrap">
        <FormSelect label="Serviço" :required="false" :dataList="typeServices()" v-model:modelValue="filters.servico" classPrefix="searchbar" />

        <FormSelect label="Status" :required="false" :dataList="invoiceStatus()" v-model:modelValue="filters.status" classPrefix="searchbar" />

        <FormInput label="Data Vencimento" :required="false" v-model:modelValue="filters.data_vencimento" typex="date" classPrefix="searchbar"/>

        <div class="searchbar__group">
          <label for="" class="searchbar__label">
            Pesquisar:
          </label>

          <input class="searchbar__input searchbar__input--cliente" type="text" v-model="filters.search" @keyup.enter="doSearch()" >
        </div>
      </div>
    </HeroSection>

    <div class="container">

      <div class="table">
        <DataLoading rows="5" height="100px" v-if="loading"/>

        <NoData v-if="!loading && dataList.length == 0"/>

        <div class="table__responsive" v-if="!loading && dataList.length">
          <table>
            <thead>
              <tr>
                <th class="a-center">#</th>
                <th class="a-left">Cliente</th>
                <th class="a-center">Serviço</th>
                <th class="a-center">Vencimento</th>
                <th class="a-right">Valor Fatura</th>
                <th class="a-right">Valor Pago</th>
                <th class="a-right">Valor Recebido</th>
                <th class="a-center">Data Pagamento</th>
                <th class="a-center">Visto</th>
                <th class="a-center">S</th>
                <th class="a-center">Nota Fiscal</th>
                <th class="a-center">Status</th>
                <th class="a-center">-</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in dataList" :key="index">
                <td class="a-center">{{ padVal(item._cod, 4) }}</td>
                <td class="table__box">
                  <div class="table__lbox">
                    <img v-if="!item.image" :src="baseUrl('/img/user.jpg')" :alt="item.company">
                    <img v-if="item.image" :src="item.image" :alt="item.company">
                  </div>

                  <div class="table__rbox">
                    <span>
                      {{ cutString(item.customer.company, 4) }}
                    </span>

                    <span>
                      {{ cutString(item.customer.name, 5) }}
                    </span>

                    <span>
                      {{ docMask(item.customer.document, true) }}
                    </span>
                  </div>
                </td>
                <td class="a-center">
                  <BadgetInfo :label="typeServices(item.service_type, 'label')" :color="typeServices(item.service_type, 'color')" />
                </td>
                <td class="a-right">
                  {{ brDate(item.maturity_date) }}
                </td>
                <td class="a-right">
                  {{ brMoney(item.invoice_value) }}
                </td>
                <td class="a-right">
                  {{ brMoney(item.paid_value) }}
                </td>
                <td class="a-right">
                  {{ brMoney(item.received_value) }}
                </td>
                <td class="a-center">
                  {{ brDate(item.paid_date) }}
                </td>
                <td class="a-center">
                  <span class="tview" v-if="!item.viewed">
                    <font-awesome-icon :icon="['fa', 'eye-slash']" />
                    {{ item.views }}
                  </span>

                  <span class="tview tview--has"  v-if="item.viewed">
                    <font-awesome-icon :icon="['fa', 'eye']"/>
                    {{ item.views }}
                  </span>
                </td>
                <td class="a-center">
                  <span class="tsuspension" :class="item.has_suspension ? 'tsuspension--has' : ''">
                    <font-awesome-icon :icon="['fa', 'triangle-exclamation']" />
                  </span>
                </td>
                <td class="a-center">
                  <BadgetInfo :label="nfseStatus(item.nfse_data?.nfse_status, 'label', true)" :color="nfseStatus(item.nfse_data?.nfse_status, 'color', true)" />
                </td>
                <td class="a-center">
                  <BadgetInfo :label="invoiceStatus(item.invoice_status, 'label')" :color="invoiceStatus(item.invoice_status, 'color')" />
                </td>
                <td class="a-center">
                  <div class="flex flex-m">
                    <router-link class="table__go" :to="`/faturas/f/${item._id}`" title="Editar">
                      <font-awesome-icon :icon="['fas', 'pen-fancy']" />
                    </router-link>

                    <router-link custom v-slot="{ href, navigate }"  :to="`/cliente/fatura/${item.token}/noview`">
                      <a :href="href" @click="navigate" target="_blank" class="table__go" title="Ver Fatura">
                        <font-awesome-icon :icon="['fa', 'barcode']" />
                      </a>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PaginationGuide prefix="faturas" :data="pagination" :filters="filters" />
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import NoData from '@components/_partials/NoData.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import FormSelect from '@components/_form/FormSelect.vue';
import FormInput from '@components/_form/FormInput.vue';
import BadgetInfo from '@components/_partials/BadgetInfo.vue';
import PaginationGuide from '@components/_partials/PaginationGuide.vue';
import { baseUrl, brMoney, padVal, brDate, docMask, phoneMask, cutString} from '@helpers/functions';
import { typeServices, schedulePeriod, isActive, nfseStatus, invoiceStatus } from '@helpers/constants'
import fatura from '@services/fatura';

export default {
  components: {
    HeroSection,
    PaginationGuide,
    NoData,
    BadgetInfo,
    FormInput,
    FormSelect,
    DataLoading
  },
  methods: {
    baseUrl,
    brDate,
    padVal,
    docMask,
    phoneMask,
    brMoney,
    typeServices,
    schedulePeriod,
    isActive,
    cutString,
    nfseStatus,
    invoiceStatus,
    initLoad() {
      const _this = this;
      const { servico, status, data_vencimento, data_pagamento, semana, search, mes } = _this.filters;
      const params = {
        page: _this.$route.query.page ? _this.$route.query.page : null,
        servico,
        status,
        data_vencimento,
        data_pagamento,
        semana,
        mes,
        search
      };

      _this.loading = true;
      fatura.retrieve(params).then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          const { docs, ...pagination } = res.data;
          _this.dataList = docs;
          _this.pagination = pagination;
        }
      });
    },
    doSearch() {
      const _this = this;
      const search = _this.filters.search;
      if(search.length > 3) {
        _this.initLoad();
        _this.filters.search = '';
      }
      if(!search.length) {
        _this.initLoad();
      }
    },
    mountQueryString() {
      const _this = this;
      const { servico, status, data_vencimento, data_pagamento, semana, mes } = _this.$route.query;

      _this.filters = {
        servico,
        status,
        data_vencimento,
        data_pagamento,
        semana,
        mes
      }
    },
    goToWithQueryString(key, filter) {
      let path = 'faturas';
      let { ...queries } = this.$route.query;

      queries[key] = filter;
      this.$router.push({
        path,
        query: queries
      });
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      pagination: {},
      filters: {}
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    },
    'filters.servico'(servico) {
      this.goToWithQueryString('servico', servico);
    },
    'filters.status'(status) {
      this.goToWithQueryString('status', status);
    },
    'filters.data_vencimento'(data_vencimento) {
      this.goToWithQueryString('data_vencimento', data_vencimento);
    }
  },
  mounted(){
    this.mountQueryString();
    this.initLoad(false);
  }
};

</script>
