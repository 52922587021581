
const storage = {};

storage.set = (item, value) => {
  localStorage.setItem(item, JSON.stringify(value));
}

storage.get = (item, prop) => {
  if(!prop) {
    return JSON.parse(localStorage.getItem(item));
  }

  if(prop) {
    let data = JSON.parse(localStorage.getItem(item));
    if(data && prop in data) return data[prop];
    return false;
  }
}

storage.clear = (item, prop) => {
  if(!prop) {
    localStorage.removeItem(item);
  }

  if(prop) {
    let data = JSON.parse(localStorage.getItem(item));
    if(data && prop in data){
      data[prop] = null;
      localStorage.setItem(item,JSON.stringify(data));
    }
  }
}

export default storage;
