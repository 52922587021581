<template>
  <div class="form__group" :style="`--width: ${width || '100%'}`">
    <label class="form__label">
      {{ label }}:
    </label>

    <div class="form__searcher">
      <input type="text" class="form__input" @input="updateValue" :value="modelValue"/>

      <ul class="form__list" v-show="dataList.length > 0">
        <li v-for="(item, index) in dataList" :key="index" :value="item.value" @click="itemClick(item)">
          <div class="table__box">
            <div class="table__lbox">
              <img v-if="!item.image" :src="baseUrl('/img/user.jpg')" :alt="item.company">
              <img v-if="item.image" :src="item.image" :alt="item.company">
            </div>

            <div class="table__rbox">
              <span>
                {{ item.company }}
              </span>

              <span>
                {{ item.name }}
              </span>

              <span>
                {{ docMask(item.document, true) }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { baseUrl, padVal, docMask } from '@helpers/functions';

export default {
  name: 'FormSearch',
  props: {
    label: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false
    },
    dataList: {
      type: Array,
      required: true
    },
    modelValue: {
      type: String,
      required: false
    },
    modelItem: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:modelItem'],
  methods: {
    baseUrl,
    padVal,
    docMask,
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    itemClick(item) {
      this.$emit('update:modelItem', item);
    }
  }
}
</script>
