<template>
  <div class="badget">
    <span class="badget__span" :style="`--color: ${color || '#CCC'}`">
      {{ label }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'badgetInfo',
  props: {
    label: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  }
}
</script>
