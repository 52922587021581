<template>
  <div class="form__group a-center" :style="`--width: ${width || '100%'}`">
    <label class="form__label">
      {{ label }}:
      <font-awesome-icon :icon="['fas', 'circle-exclamation']" v-if="required" />
    </label>

    <div class="form__checkbox">
      <label class="form__switch">
        <input type="checkbox" :checked="modelValue" @change="updateValue">
        <span class="form__slider"></span>
      </label>
    </div>

    <!-- <input :type="!typex ? 'text' : typex" class="form__input" :value="modelValue" @input="updateValue" v-if="!money && !mask" :id="idx" /> -->

  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    label: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.checked);
    }
  }
}
</script>
