<template>
  <section>
    <HeroSection headName="Cronlogs" :searchBar="true" :addButton="false">
      <div class="searchbar__wrap">
        <div class="searchbar__group">
          <label for="" class="searchbar__label">
            Pesquisar:
          </label>

          <input class="searchbar__input searchbar__input--cliente" type="text" v-model="filters.search" @keyup.enter="doSearch()" >
        </div>
      </div>
    </HeroSection>

    <div class="container">

      <div class="table">
        <DataLoading rows="5" height="100px" v-if="loading"/>

        <NoData v-if="!loading && dataList.length == 0"/>

        <div class="table__responsive" v-if="!loading && dataList.length">
          <table>
            <thead>
              <tr>
                <th class="a-center">Data / Hora Evento</th>
                <th class="a-center">Tipo</th>
                <th class="a-left">Descrição</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in dataList" :key="index">
                <td class="a-center">{{ brDateWithTime(item.created_at) }}</td>
                <td class="a-center non-width">{{ item.type }}</td>
                <td class="a-left">{{ item.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PaginationGuide prefix="cronlogs" :data="pagination" :filters="filters"/>
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import NoData from '@components/_partials/NoData.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import PaginationGuide from '@components/_partials/PaginationGuide.vue';
import cronlog from '@services/cronlog';
import moment from 'moment';

export default {
  components: {
    HeroSection,
    PaginationGuide,
    NoData,
    DataLoading
  },
  methods: {
    initLoad() {
      const _this = this;
      const { search } = _this.filters;
      const params = {
        page: _this.$route.query.page ? _this.$route.query.page : null,
        search
      };

      _this.loading = true;
      cronlog.retrieve(params).then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          const { docs, ...pagination } = res.data;
          _this.dataList = docs;
          _this.pagination = pagination;
        }
      });
    },
    doSearch() {
      const _this = this;
      const search = _this.filters.search;
      if(search.length > 3) {
        _this.initLoad();
        _this.filters.search = '';
      }
      if(!search.length) {
        _this.initLoad();
      }
    },
    brDateWithTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      pagination: {},
      filters: {}
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    }
  },
  mounted(){
    this.initLoad(false);
  }
};

</script>
