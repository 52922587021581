
import api from 'api';
import { processPromise, obj2Prams } from '@helpers/functions';

const cliente = {};

cliente.retrieve = (params = {}) => {
  const querystring = obj2Prams(params);

  return new Promise((resolve, reject) => {
    let endpoint = `/api/clientes`;
    endpoint = querystring ? `${endpoint}?${querystring}` : endpoint;

    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

cliente.show = (_id) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/cliente/${_id}`;
    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

cliente.save = (_id, data) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/cliente`;
    endpoint = _id ? `${endpoint}/${_id}` : endpoint;
    api.post(endpoint, data).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

export default cliente
