<template>
  <div class="loading">
    <div class="loading__row" v-for="(item, index) in rowLoop" :key="index">
      <span class="loading__loader" :style="`height: ${height}`"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataLoading',
  props: {
    rows: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rowLoop: []
    }
  },
  methods: {
    initTable() {
      const _this = this;
      const rows = _this.rows;
      const rowLoop = [];

      for(let i = 1; i <= rows; i++) {
        rowLoop.push(i);
      }

      _this.rowLoop = rowLoop;

    }
  },
  mounted(){
    this.initTable();
  }
}
</script>
