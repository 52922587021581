
import api from 'api';
import { processPromise, obj2Prams } from '@helpers/functions';

const cronlog = {};

cronlog.retrieve = (params = {}) => {
  const querystring = obj2Prams(params);

  return new Promise((resolve, reject) => {
    let endpoint = `/api/cronlogs`;
    endpoint = querystring ? `${endpoint}?${querystring}` : endpoint;

    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

export default cronlog
