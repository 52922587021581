<template>
  <section>
    <HeroSection headName="Cadastro de Agendamento" :searchBar="false" :addButton="false">
    </HeroSection>

    <div class="container">

      <form class="form">
        <div class="form__box" v-if="loading">
          <h2 class="form__name">
            <DataLoading rows="1" height="50px" />
          </h2>

          <div class="form__content">
            <DataLoading rows="4" height="80px" />
          </div>
        </div>

        <div class="form__wrap" v-if="!loading">
          <div class="form__box">
            <h2 class="form__name">
              Dados do Cliente
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormSearch label="Pesquisar Cliente" v-model:modelValue="searcher" v-model:modelItem="customer" :dataList="customersList" />
              </div>

              <div class="form__row">
                <FormInput label="CPF" :required="true" v-model:modelValue="helpers.cpf" mask="000.000.000-00" v-if="isCpf" idx="cpf" width="24%"/>

                <FormInput label="CNPJ" :required="true" v-model:modelValue="helpers.cnpj" mask="00.000.000/0000-00" v-if="!isCpf"  idx="cnpj"  width="24%"/>

                <FormInput label="Nome Fantasia" :required="true" v-model:modelValue="model.customer.name" width="26%"/>

                <FormInput label="Razão Social" :required="true" v-model:modelValue="model.customer.company" width="26%"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Dados de Agendamento
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="Valor da Fatura" :required="true" v-model:modelValue="model.schedule_value" money width="20%"/>

                <FormInput label="Próximo vencimento" :required="true" v-model:modelValue="model.next_maturity" typex="date" width="25%"/>

                <FormInput label="Dias de Envio" :required="true" v-model:modelValue="model.days_to_send" width="25%"/>

                <FormSelect label="Tipo de Serviço" :required="true" :dataList="typeServices()" v-model:modelValue="model.service_type" width="30%" />
              </div>

              <div class="form__row">
                <FormSelect label="Periodicidade" :required="true" :dataList="schedulePeriod()" v-model:modelValue="model.periodicidy" width="20%" />

                <FormInput label="Parcelas" :required="false" v-model:modelValue="model.installments_to_pay" typex="number" width="20%"/>

                <FormInput label="Descritivo Nfse" :required="false" v-model:modelValue="model.short_desc"  width="60%"/>
              </div>

              <div class="form__row form__row--nowrap">
                <FormCheckbox label="Agendamento Ativo" :required="true" v-model:modelValue="model.is_active" />

                <FormCheckbox label="Emitir Nota Fiscal" :required="true" v-model:modelValue="model.has_nfse"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Descritivo
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormEditor label="Descritivo de Fatura" :required="true" v-model:modelValue="model.full_desc"/>
              </div>
            </div>
          </div>

          <FormBottom :data="button" @submit="submit()" />

        </div>
      </form>
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import FormSearch from '@components/_form/FormSearch.vue';
import FormInput from '@components/_form/FormInput.vue';
import FormSelect from '@components/_form/FormSelect.vue';
import FormCheckbox from '@components/_form/FormCheckbox.vue';
import FormEditor from '@components/_form/FormEditor.vue';
import FormBottom from '@components/_form/FormBottom.vue';
import { baseUrl, padVal, brDate, docMask, phoneMask, brMoney, globalDate } from '@helpers/functions';
import { typeServices, schedulePeriod, isActive } from '@helpers/constants'
import agendamento from '@services/agendamento';
import cliente from '@services/cliente';

export default {
  components: {
    HeroSection,
    DataLoading,
    FormSearch,
    FormInput,
    FormSelect,
    FormCheckbox,
    FormEditor,
    FormBottom
  },
  data() {
    return {
      loading: false,
      button: { spinner: false, label: 'Salvar Agendamento', color: 'primary' },
      model: { customer: {}, days_to_send: 5, is_active: false, has_nfse: false },
      customersList: [],
      customer: {},
      dataList: [],
      pagination: {},
      searcher: '',
      search: '',
      isCpf: true,
      helpers: {
        cpf: '',
        cnpj: ''
      }
    }
  },
  methods: {
    baseUrl,
    brDate,
    padVal,
    docMask,
    phoneMask,
    brMoney,
    globalDate,
    typeServices,
    schedulePeriod,
    isActive,
    loadForm: function(_id) {
      const _this = this;
      _this.loading = true;

      agendamento.show(_id).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          _this.model = res.data;
          _this.loading = false;

          _this.model.schedule_value = brMoney(_this.model.schedule_value, 'decimal');
          _this.model.next_maturity = globalDate(_this.model.next_maturity);

          _this.helpers.cpf = _this.model.customer.document;
          _this.isCpf = true;
          if(_this.model.customer.document.length == 14) {
            _this.helpers.cnpj = _this.model.customer.document;
            _this.isCpf = false;
          }
        }
      });
    },
    submit() {
      const _this = this;
      const _id = _this.hasIdx();
      const button = _this.button;

      let data = _this.model;

      console.log('DATA', data)

      _this.button = {
        spinner: true,
        label: 'Salvando...',
        color: 'warning'
      }
      agendamento.save(_id, data).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          _this.button = {
            spinner: false,
            label: 'Salvo com sucesso!',
            color: 'success'
          }

          setTimeout(() => this.$router.push('/agendamentos'), 1000);
        }

        if(typeof res === 'undefined' || !res.success) {
          _this.button = {
            spinner: false,
            label: 'Houve um erro ao processar.',
            color: 'danger'
          }
          setTimeout(() => _this.button = button, 3000);
        }
      })

    },
    hasIdx() {
      const _this = this;
      return _this.$route.params.id ? _this.$route.params.id : null;
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    },
    'helpers.cpf'(doc) {
      const _this = this;
      if(doc && doc.length >= 15) {
        _this.isCpf = false;
        _this.helpers.cnpj = doc;
        _this.model.document = doc;
        setTimeout(() => {
          document.getElementById('cnpj').focus();
        }, 200);
      }
    },
    'helpers.cnpj'(doc) {
      const _this = this;
      if(doc && doc.length <= 11) {
        _this.isCpf = true;
        _this.helpers.cpf = doc;
        _this.model.document = doc;
        setTimeout(() => {
          document.getElementById('cpf').focus();
        }, 200);
      }
    },
    'searcher'(search) {
      const _this = this;
      if(search && search.length > 3) {
        cliente.retrieve({ search }).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          const { docs } = res.data;
          _this.customersList = docs;
        }
      });
      }

      if(!search || search.length == 0) {
        _this.customersList = [];
      }
    },
    'customer'(customer) {
      const _this = this;
      const { _id, name, company, document } = customer;

      _this.helpers.cpf = document;
      _this.isCpf = true;
      if(document.length == 14) {
        _this.helpers.cnpj = document;
        _this.isCpf = false;
      }

      _this.model.customer = { _id, name, company };
      _this.customersList = [];
      _this.searcher = '';
    }
  },
  mounted(){
    const _id = this.hasIdx();
    if(_id) this.loadForm(_id);
  }
};

</script>
