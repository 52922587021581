<template>
  <section>
    <HeroSection headName="Bancos" :searchBar="false" :addButton="false">
    </HeroSection>
  </section>

  <div class="container">
    <DataLoading rows="2" height="180px" v-if="loading"/>

    <div class="bcontent" v-if="!loading">
      <ul class="blist">
        <li class="bitem" v-for="(item, index) in bboxes" :key="index">
          <div class="bbox" :style="`--color: ${item.color}`">
            <div class="bhead">
              <font-awesome-icon :icon="['fa', item.icon]" />

              {{ item.title }}
            </div>

            <div class="bbody">
              <span class="bnum">
                {{ brMoney(item.value) }}
              </span>

              <span class="bpos" v-if="item.bank_key && item.value > 0" @click="doBankPix(item.bank_pix)">
                <font-awesome-icon :icon="['fa', 'shuffle']"/>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import DataLoading from '@components/_partials/DataLoading.vue';
import HeroSection from '@components/_partials/HeroSection.vue';
import { brMoney } from '@helpers/functions';
import banco from '@services/banco';

export default {
  components: {
    DataLoading,
    HeroSection
  },
  data() {
    return {
      loading: false,
      bboxes: [
        { icon: 'sack-dollar', color: '#3CA6A6', title: 'Saldo Bancário', bank_key: null, value: 0 },
        { icon: 'building-columns', color: '#006DFF', title: 'Iugu Flize', bank_key: 'iugu_flize', value: 0 },
        { icon: 'building-columns', color: '#29C800', title: 'Sicredi', bank_key: null, value: 0 },
        { icon: 'building-columns', color: '#F27405', title: 'Inter Flize', bank_key: 'inter_flize', value: 0 },
        { icon: 'building-columns', color: '#F27405', title: 'Inter NextCondo', bank_key: 'inter_nextcondo', value: 0 },
        { icon: 'building-columns', color: '#F27405', title: 'Inter PGZ', bank_key: 'inter_pgz', value: 0 }
      ]
    }
  },
  methods: {
    brMoney,
    initLoad() {
      const _this = this;
      _this.loading = true;
      banco.details().then((res) => {
        _this.loading = false;
        let total = 0;

        if(typeof res !== 'undefined' && res.success) {
          _this.bboxes.forEach(item => {
            if(item.bank_key && typeof res.data[item.bank_key] !== 'undefined') {
              item.value = res.data[item.bank_key];
              item.bank_pix = item.bank_key.replace('_', '-');
              total += item.value;
            }
          });

          _this.bboxes[0].value = total;
        }
      });
    },
    doBankPix(bank_pix) {
      const _this = this;
      _this.loading = true;

      banco.pix(bank_pix).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          setTimeout(() => _this.initLoad(), 2000)
        }
      });
    }
  },
  mounted(){
    this.initLoad();
  }
}
</script>
