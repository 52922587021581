<template>
  <section></section>
</template>

<script>

import storage from '@/config/storage';

export default {
  mounted(){
    storage.clear('flizecore--user');
    this.$router.push('/login')
  }
}
</script>
