<template>
  <section>
    <HeroSection headName="Cadastro de Usuário" :searchBar="false" :addButton="false">
    </HeroSection>

    <div class="container">

      <form class="form">
        <div class="form__box" v-if="loading">
          <h2 class="form__name">
            <DataLoading rows="1" height="50px" />
          </h2>

          <div class="form__content">
            <DataLoading rows="4" height="80px" />
          </div>
        </div>

        <div class="form__wrap" v-if="!loading">
          <div class="form__box">
            <h2 class="form__name">
              Dados Gerais
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="Nome Completo" :required="true" v-model:modelValue="model.fullname"/>
              </div>

              <div class="form__row">
                <FormSelect label="Permissão" :required="true" :dataList="userRoles()" v-model:modelValue="model.role" />
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Acesso
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="E-mail de Acesso" :required="true" v-model:modelValue="model.email"/>

                <FormInput label="Senha" :required="true" v-model:modelValue="model.password"/>
              </div>
            </div>
          </div>

          <FormBottom :data="button" @submit="submit()" />

        </div>
      </form>
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import FormInput from '@components/_form/FormInput.vue';
import FormSelect from '@components/_form/FormSelect.vue';
import FormBottom from '@components/_form/FormBottom.vue';
import { userRoles } from '@helpers/constants';
import { baseUrl, padVal, brDate, globalDate, docMask, phoneMask } from '@helpers/functions';
import usuario from '@services/usuario';
import viacep from '@services/viacep';

export default {
  components: {
    HeroSection,
    DataLoading,
    FormInput,
    FormSelect,
    FormBottom
  },
  data() {
    return {
      loading: false,
      button: { spinner: false, label: 'Salvar Usuário', color: 'primary' },
      model: { },
      dataList: [],
      pagination: {},
      search: ''
    }
  },
  methods: {
    baseUrl,
    brDate,
    padVal,
    docMask,
    phoneMask,
    userRoles,
    loadForm: function(_id) {
      const _this = this;
      _this.loading = true;

      usuario.show(_id).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          _this.model = res.data;
          _this.loading = false;

          _this.model.password = '';
          _this.model.created_at = globalDate(_this.model.created_at);
        }
      });
    },
    submit() {
      const _this = this;
      const _id = _this.hasIdx();
      const button = _this.button;
      let data = _this.model;

      _this.button = {
        spinner: true,
        label: 'Salvando...',
        color: 'warning'
      }
      usuario.save(_id, data).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          _this.button = {
            spinner: false,
            label: 'Salvo com sucesso!',
            color: 'success'
          }

          setTimeout(() => this.$router.push('/usuarios'), 1000);
        }

        if(typeof res === 'undefined' || !res.success) {
          _this.button = {
            spinner: false,
            label: 'Houve um erro ao processar.',
            color: 'danger'
          }

          setTimeout(() => _this.btnLabel = button, 3000);
        }
      })

    },
    hasIdx() {
      const _this = this;
      return _this.$route.params.id ? _this.$route.params.id : null;
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    },
    'helpers.cpf'(doc) {
      const _this = this;
      if(doc && doc.length >= 15) {
        _this.isCpf = false;
        _this.helpers.cnpj = doc;
        _this.model.document = doc;
        setTimeout(() => {
          document.getElementById('cnpj').focus();
        }, 200);
      }
    },
    'helpers.cnpj'(doc) {
      const _this = this;
      if(doc && doc.length <= 11) {
        _this.isCpf = true;
        _this.helpers.cpf = doc;
        _this.model.document = doc;
        setTimeout(() => {
          document.getElementById('cpf').focus();
        }, 200);
      }
    },
    'model.contact.zip_code'(cep) {
      const _this = this;
      if(cep && cep.length == 9) {
        viacep.search(cep).then((res) => {
          if(typeof res.erro !== 'undefined') {
            return;
          }

          _this.model.contact.street_name = res.logradouro;
          _this.model.contact.neighborhood = res.bairro;
          _this.model.contact.city = res.localidade;
          _this.model.contact.state = res.uf;
        })
      }
    }
  },
  mounted(){
    const _id = this.hasIdx();
    if(_id) this.loadForm(_id);
  }
};

</script>
