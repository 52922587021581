
export function typeServices(service = null, key = null) {
  const services =  [
    { label: 'Hospedagem Cloud', value: 'hospedagem_cloud', color: '#418EF2' },
    { label: 'Revenda Cloud', value: 'revenda_cloud', color: '#064F6E' },
    { label: 'Desenvolvimento Web', value: 'desenvolvimento_web', color: '#054661' },
    { label: 'Ajustes Web', value: 'ajustes_web', color: '#0A7CAD' },
    { label: 'Sistema Nota Fiscal', value: 'sistema_nota_fiscal', color: '#0DAAED' },
    { label: 'Sistema ModulaPro Fiscal', value: 'sistema_nmodulapro_fiscal', color: '#38184C' },
    { label: 'Sistema ModulaPro Gestão', value: 'sistema_nmodulapro_gestao', color: '#019587' },
    { label: 'Sistema Diversos', value: 'sistema_diversos', color: '#0F3BBF' },
    { label: 'Registro de Domínio', value: 'registro_de_dominio', color: '#591C21' },
    { label: 'Outros', value: 'outros', color: '#9FC131' }
  ];

  if(service === null) return services;

  const selected = services.filter(item => item.value === service);
  return selected[0][key];
}


export function monthLabel(month = null) {
  const months = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];

  if(month === null) return months;

  return months[month - 1];
}


export function invoiceStatus(status = null, key = null) {
  const stats = [
    { label: 'Emitida', value: 'emitida', color: '#04D939', class: 'fstatus__green' },
    { label: 'Paga', value: 'paga', color: '#012340', class: 'fstatus__gray'  },
    { label: 'Arquivada', value: 'arquivada', color: '#3E688C', class: 'fstatus__gray'  },
    { label: 'Vencida', value: 'vencida', color: '#CF3830', class: 'fstatus__orange'  },
    { label: 'Vencendo', value: 'vencendo', color: '#F58C38', class: 'fstatus__red'  },
    { label: 'Cancelada', value: 'cancelada', color: '#5D6266', class: 'fstatus__gray'  },
    { label: 'Outros', value: 'outros', color: '#5D6266', class: 'fstatus__gray'  },
    { label: 'Pendente', value: 'pendente', color: '#F58C38', class: 'fstatus__orange'  },
  ];

  if(status === null) return stats;

  const selected = stats.filter(item => item.value === status);
  return selected[0][key];
}


export function nfseStatus(nfse = null, key = null, hasNull = false) {
  const nfses = [
    { label: 'Autorizada', value: 'autorizado', color: '#05899E' },
    { label: 'Processando', value: 'processando', color: '#F27405' },
    { label: 'Cancelada', value: 'cancelada', color: '#5D6266' },
    { label: 'Inexistente', value: 'inexistente', color: '#818274' },
    { label: 'Erro', value: 'erro', color: '#F21B1B' }
  ];

  if(nfse === null && hasNull === false) return nfses;
  if(nfse === null && hasNull === true) return nfses[3][key];

  const selected = nfses.filter(item => item.value === nfse);
  return selected[0][key];
}

export function schedulePeriod(schedule = null, key = null) {
  const schedules =  [
    { label: 'Mensal', value: 1, color: '#127369' },
    { label: 'Bimestral', value: 2, color: '#8AA6A3' },
    { label: 'Trimestral', value: 3, color: '#8AA6A3' },
    { label: 'Quadrimestral', value: 4, color: '#8AA6A3' },
    { label: 'Semestral', value: 6, color: '#8AA6A3' },
    { label: 'Anual', value: 12, color: '#8AA6A3' },
    { label: 'Parcelamento', value: 0, color: '#4C5958' }
  ];

  if(schedule === null) return schedules;

  const selected = schedules.filter(item => item.value === schedule);
  return selected[0][key];
}


export function isActive(status = null, key = null) {
  const stats = [
    { label: 'Ativo', value: true, color: '#60BF81' },
    { label: 'Inativo', value: false, color: '#C5C5B9' },
  ];

  if(status === null) return stats;

  const selected = stats.filter(item => item.value === status);
  return selected[0][key];
}


export function userRoles(role = null, key = null) {
  const roles = [
    { label: 'Administrador', value: 'admin', color: '#3C3D40' },
    { label: 'Gerente', value: 'gerente', color: '#9FA3A6' },
  ];

  if(role === null) return roles;

  const selected = roles.filter(item => item.value === role);
  return selected[0][key];
}
