
import api from 'api';
import { processPromise, obj2Prams } from '@helpers/functions';

const fatura = {};

fatura.retrieve = (params = {}) => {
  const querystring = obj2Prams(params);

  return new Promise((resolve, reject) => {
    let endpoint = `/api/faturas`;
    endpoint = querystring ? `${endpoint}?${querystring}` : endpoint;

    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

fatura.show = (_id) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/fatura/${_id}`;
    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

fatura.save = (_id, data) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/fatura`;
    endpoint = _id ? `${endpoint}/${_id}` : endpoint;
    api.post(endpoint, data).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

fatura.archive = (_id, data) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/fatura/a/${_id}`;
    api.post(endpoint, data).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

fatura.cancel = (_id, data) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/fatura/c/${_id}`;
    api.post(endpoint, data).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

fatura.public = (_id, no) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/cliente/fatura/${_id}`;
    endpoint = no ? `${endpoint}/${no}` : endpoint;
    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

export default fatura
