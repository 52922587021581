
import api from 'api';
import { processPromise } from '@helpers/functions';

const dashboard = {};

dashboard.details = () => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/dashboard/`;
    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

export default dashboard
