<template>
  <section>
    <HeroSection headName="Clientes" goLabel="Cadastrar Clientes" goRoute="/clientes/f" :searchBar="true" :addButton="true">
      <div class="searchbar__wrap">
        <div class="searchbar__group">
          <label for="" class="searchbar__label">
            Pesquisar:
          </label>

          <input class="searchbar__input searchbar__input--cliente" type="text" v-model="filters.search" @keyup.enter="doSearch()" >
        </div>
      </div>
    </HeroSection>

    <div class="container">

      <div class="table">
        <DataLoading rows="5" height="100px" v-if="loading"/>

        <NoData v-if="!loading && dataList.length == 0"/>

        <div class="table__responsive" v-if="!loading && dataList.length">
          <table>
            <thead>
              <tr>
                <th class="a-center">#</th>
                <th class="a-left">Cliente</th>
                <th class="a-left">E-mail</th>
                <th class="a-left">Celular / Telefone</th>
                <th class="a-center">Cliente desde</th>
                <th class="a-center">-</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in dataList" :key="index">
                <td class="a-center">{{ padVal(item._cod, 4) }}</td>
                <td class="table__box">
                  <div class="table__lbox">
                    <img v-if="!item.image" :src="baseUrl('/img/user.jpg')" :alt="item.company">
                    <img v-if="item.image" :src="item.image" :alt="item.company">
                  </div>

                  <div class="table__rbox">
                    <span>
                      {{ item.company }}
                    </span>

                    <span>
                      {{ item.name }}
                    </span>

                    <span>
                      {{ docMask(item.document, true) }}
                    </span>
                  </div>
                </td>
                <td class="a-left">
                  <span class="s-block" v-if="item.contact.email1">{{ item.contact.email1 }}</span>
                  <span class="s-block" v-if="item.contact.email2">{{ item.contact.email2 }}</span>
                  <span class="s-block" v-if="item.contact.email3">{{ item.contact.email3 }}</span>
                  <span class="s-block" v-if="item.contact.email4">{{ item.contact.email4 }}</span>
                </td>
                <td class="a-left">
                  <span class="s-block" v-if="item.contact.celphone1">{{ phoneMask(item.contact.celphone1) }}</span>
                  <span class="s-block" v-if="item.contact.celphone2">{{ phoneMask(item.contact.celphone2) }}</span>
                  <span class="s-block" v-if="item.contact.celphone3">{{ phoneMask(item.contact.celphone3) }}</span>
                </td>
                <td class="a-center">{{ brDate(item.created_at) }}</td>
                <td class="a-center">
                  <router-link class="table__go" :to="`/clientes/f/${item._id}`" title="Editar">
                    <font-awesome-icon :icon="['fas', 'pen-fancy']" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PaginationGuide prefix="clientes" :data="pagination" :filters="filters"/>
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import NoData from '@components/_partials/NoData.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import PaginationGuide from '@components/_partials/PaginationGuide.vue';
import { baseUrl, padVal, brDate, docMask, phoneMask } from '@helpers/functions';
import cliente from '@services/cliente';

export default {
  components: {
    HeroSection,
    PaginationGuide,
    NoData,
    DataLoading
  },
  methods: {
    baseUrl,
    brDate,
    padVal,
    docMask,
    phoneMask,
    initLoad() {
      const _this = this;
      const { search } = _this.filters;
      const params = {
        page: _this.$route.query.page ? _this.$route.query.page : null,
        search
      };

      _this.loading = true;
      cliente.retrieve(params).then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          const { docs, ...pagination } = res.data;
          _this.dataList = docs;
          _this.pagination = pagination;
        }
      });
    },
    doSearch() {
      const _this = this;
      const search = _this.filters.search;
      if(search.length > 3) {
        _this.initLoad();
        _this.filters.search = '';
      }
      if(!search.length) {
        _this.initLoad();
      }
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      pagination: {},
      filters: {}
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    }
  },
  mounted(){
    this.initLoad(false);
  }
};

</script>
